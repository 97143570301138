import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const RelatedPostsComponent = ({ posts }) => {
    if (posts.length) {
        return (
            <aside className="py-6">
                <h2 className="text-3xl font-bold mb-1 tracking-tight">You may also like...</h2>
                <div className="md:flex md:-mx-2">
                    {posts.map((p) => (
                        <div key={p.slug} className="my-2 md:w-1/2 md:mx-2 rounded-md bg-gradient-to-r from-gray-50 to-slate-100">
                            <Link to={p.slug} className="overflow-hidden">
                                <div className="p-4">
                                    <div className="font-bold text-xl mb-2">{p.title}</div>
                                    <p>
                                        {p.summary}
                                    </p>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </aside>
        );
    }
    else {
        return null;
    }
};

RelatedPostsComponent.propTypes = {
    posts: PropTypes.array
}

export default RelatedPostsComponent;